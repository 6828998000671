<template>
  <b-form-group>
    <multiselect
      id="healthPlan"
      v-model="healthPlan"
      :options="healthPlansOptions"
      :option-height="40"
      :showLabels="false"
      :searchable="true"
      :internalSearch="false"
      :allowEmpty="true"
      :showNoResults="true"
      placeholder="Buscar convênio"
      class="with-border"
      @select="selectHealthPlan"
      @search-change="handleSearch"
    >
      <template slot="caret">
        <div class="chevron">
          <ChevronDown v-if="!healthPlan"/>
        </div>
      </template>
      <template slot="option" slot-scope="props">
        {{ props?.option?.label }}
      </template>
      <template slot="singleLabel" slot-scope="props">
        <Ellipsis>
          {{ props?.option?.label }}
        </Ellipsis>
      </template>
      <template slot="noOptions"> Nenhuma opção </template>
    </multiselect>
    <Close
      v-if="healthPlan"
      class="close-item-icon mt-1"
      :class="{ 'diferent-top': diferentTop }"
      @click="clearHealthPlan"
    />
  </b-form-group>
</template>

<script>
import { getCurrentClinic } from '@/utils/localStorageManager'

export default {
  name: 'HealthPlanFilter',
  components: {
    ChevronDown: () => import('@/assets/icons/chevron-down.svg'),
    Close: () => import('@/assets/icons/close.svg'),
    Ellipsis: () => import('@/components/General/Ellipsis')
  },
  props: {
    selectHealthPlan: Function,
    diferentTop: { type: Boolean, default: false }
  },
  data() {
    return {
      healthPlan: null,
      tissHealthPlansOptions: [],
      clinic: getCurrentClinic(),
      search: null
    }
  },
  async created() {
    await this.getHealthPlans()
  },
  computed: {
    healthPlansOptions() {
      if(!this.search) return this.tissHealthPlansOptions
      return this.tissHealthPlansOptions.filter(tissHealthPlan => tissHealthPlan?.label?.toLowerCase().includes(this.search?.toLowerCase()))
    },
  },
  methods: {
    async getHealthPlans() {
      try {
        const { data } = await this.api.getActiveInvoicingTissClinicHealthPlans(
          this.clinic.id,
          1,
          null,
          null,
          true
        )
        this.tissHealthPlansOptions = data.map(healthPlan => ({
          label: healthPlan?.health_plan?.fantasy_name,
          value: healthPlan.id
        }))
      } catch (error) {
        this.$toast.error('Erro ao buscar planos de saúde')
      }
    },
    handleSearch(query){
      this.search = query
    },
    clearHealthPlan(){
      this.healthPlan = null
      this.selectHealthPlan(null)
    }
  }
}
</script>

<style lang="scss" scoped>
  .close-item-icon{
    width: 25px;
    height: 25px;
    fill: rgb(127, 145, 210);
    position: absolute;
    right: 15px;
    top: 40%;
  }

  .diferent-top{
    top: 32% !important;
  }
</style>
